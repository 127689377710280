import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Booking from './pages/Booking';
import BookingDetails from './pages/BookingDetails';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PaymentSucc from './pages/PaymentSucc';
import Login from './pages/Login';
import Office from './pages/Office';
import Bookingss from './pages/Bookingss';
import Bookingss2 from './pages/Bookingss2';
import ComingSoon from './pages/ComingSoon';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          {/* <Route path="/" element={<Home />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/bookingss" element={<Bookingss />} />
          <Route path="/bookingss2" element={<Bookingss2 />} />
          <Route path="/login" element={<Login />} />
          <Route path="/office" element={<Office />} />
          <Route path="/bookingdetails" element={<BookingDetails />} />
          <Route path="/paymentsucc" element={<PaymentSucc />} /> */}
        </Routes>
      </BrowserRouter>
    </div >
  );
}

export default App;
