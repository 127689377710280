import React from 'react'
import './BookingDetails.css'
import { db } from '../firebase';
import useRazorpay from "react-razorpay";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';


function BookingDetails() {
    const checkinDate = new Date(localStorage.getItem("checkind"))
    const checkoutDate = new Date(localStorage.getItem("checkoutd"))
    const roomid = String(localStorage.getItem("roomid"))
    const [Razorpay, isLoaded] = useRazorpay();
    const navigate = useNavigate();


    const randomAlphaNumeric = length => {
        let s = '';
        Array.from({ length }).some(() => {
            s += Math.random().toString(36).slice(2);
            return s.length >= length;
        });
        return s.slice(0, length);
    };

    setTimeout(() => {

        var diff = checkoutDate.getTime() - checkinDate.getTime();

        window.daydiff = diff / (1000 * 60 * 60 * 24);

        const yyyy = checkinDate.getFullYear();
        let mm = checkinDate.getMonth() + 1; // Months start at 0!
        let dd = checkinDate.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedToday = dd + '/' + mm + '/' + yyyy;

        document.getElementById('checkin').innerText = "Checkin Date: " + formattedToday;
        window.checkin = formattedToday

        const yyyy2 = checkoutDate.getFullYear();
        let mm2 = checkoutDate.getMonth() + 1; // Months start at 0!
        let dd2 = checkoutDate.getDate();

        if (dd2 < 10) dd2 = '0' + dd2;
        if (mm2 < 10) mm2 = '0' + mm2;

        const formattedToday2 = dd2 + '/' + mm2 + '/' + yyyy2;
        window.checkout = formattedToday2

        document.getElementById('checkout').innerText = "Checkout Date: " + formattedToday2;
    }, 500);

    const proceed = async () => {
        localStorage.setItem('name', document.getElementById("bd__name").value)
        localStorage.setItem('mobile', document.getElementById("bd__mobile").value)
        localStorage.setItem('add', document.getElementById("bd__add").value)
        localStorage.setItem('btype', document.getElementById("bd__btype").value)
        localStorage.setItem('itype', document.getElementById("bd__itype").value)
        localStorage.setItem('idnum', document.getElementById("bd__idnum").value)
        localStorage.setItem('accp', document.getElementById("bd__accp").value)
        localStorage.setItem('daydiff', window.daydiff)
        localStorage.setItem('price', window.price)
        localStorage.setItem("checkinfinal", window.checkin)
        localStorage.setItem("checkoutfinal", window.checkout)


        const today = new Date()
        const year = today.getFullYear();
        let month = today.getMonth() + 1; // Months start at 0!
        let day = today.getDate();

        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;

        const ddmmyyyy = day + '/' + month + '/' + year;
        localStorage.setItem("bookingdate", ddmmyyyy)

        // generate a uuid and set it in sessionStorage as id
        const uuid = String(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))
        localStorage.setItem("confid", uuid)

    }
    const showprice = async () => {
        const docRef = doc(db, "roomtariff", String(roomid));
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            if (document.getElementById("bd__btype").value === "privateprice") {
                document.getElementById("roomprice").innerText = "Payable: Rs." + docSnap.data().privateprice + " X " + window.daydiff + " Day(s) = Rs." + (docSnap.data().privateprice * window.daydiff) + "/-"
                window.priceid = docSnap.data().private
                window.price = docSnap.data().privateprice
            }
            if (document.getElementById("bd__btype").value === "govtdutyprice") {
                document.getElementById("roomprice").innerText = "Payable: Rs." + docSnap.data().govtdutyprice + " X " + window.daydiff + " Day(s) = Rs." + (docSnap.data().govtdutyprice * window.daydiff) + "/-"
                window.priceid = docSnap.data().govtduty
                window.price = docSnap.data().govtdutyprice
            }
            if (document.getElementById("bd__btype").value === "ondutyprice") {
                document.getElementById("roomprice").innerText = "Payable: Rs." + docSnap.data().ondutyprice + " X " + window.daydiff + " Day(s) = Rs." + (docSnap.data().ondutyprice * window.daydiff) + "/-"
                window.priceid = docSnap.data().onduty
                window.price = docSnap.data().ondutyprice
            }
        }
    }
    const toBase64 = (str) => {
        return Buffer.from(str).toString('base64');
    };

    const handlePayment = async () => {
        const createRazorpayOrder = httpsCallable(functions, 'createRazorpayOrder');
        const result = await createRazorpayOrder(); // Amount in paise

        const order = result.data;

        proceed()
        console.log(String(roomid))
        const bid = String(randomAlphaNumeric(7)).toUpperCase()
        localStorage.setItem("bid", bid)

        console.log("Payment Process Started...");
        var options = {
            key: "rzp_test_0osVk38NYu1FkH",
            key_secret: "5WnISHwT8190riVc0pXFVxnu",
            amount: 1000,//parseInt((window.price * window.daydiff) * 100),
            currency: "INR",
            order_id: order.id,
            order_receipt: String('booking_id_' + bid),
            name: "Guesthouse Booking",
            description: "Booking for DCOMP Guesthouse",
        }
        // Add a new document in collection "cities"
        setDoc(doc(db, "bookingreg", bid), {
            name: localStorage.getItem('name'),
            mobile: localStorage.getItem('mobile'),
            add: localStorage.getItem('add'),
            btype: localStorage.getItem('btype'),
            itype: localStorage.getItem('itype'),
            idnum: localStorage.getItem('idnum'),
            accp: localStorage.getItem('accp'),
            daydiff: localStorage.getItem('daydiff'),
            bprice: localStorage.getItem('price'),
            checkin: localStorage.getItem("checkinfinal"),
            checkout: localStorage.getItem("checkoutfinal")
        })


        var pay = new window.Razorpay(options);
        pay.open();
        console.log(pay)

    };

    return (
        <div className='book'>
            <center>
                <h2 style={{ color: 'maroon' }}><br />Booking Details</h2>
                <hr /><br />
                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px', paddingRight: '20px', justifyContent: 'space-between' }}>
                    <p id="checkin">Checkin Date: 2-3-44</p>
                    <p id="checkout">Checkout Date: 3-3-44</p>
                </div>
                <br />
                <label htmlFor="name"> Type of Guest: </label>
                <br />
                <select required className='join__inpBox' id="bd__btype" onChange={showprice}>
                    <option value="">Select Type of Guest</option>
                    <option value="privateprice">Private Tour</option>
                    <option value="govtdutyprice">Govt. Official</option>
                    <option value="ondutyprice">Govt. Official on Duty</option>
                </select>
                <p id="roomprice">Room Pric:</p>
                <label htmlFor="name"> Name: *</label>
                <br />
                <input required className='join__inpBox' type="text" id="bd__name" />
                <br /><br />
                <label required htmlFor="name"> Mobile No.: *</label>
                <br />
                <input required className='join__inpBox' type="number" id="bd__mobile" />
                <br /><br />
                <label htmlFor="name">Short Address (Max 40): *</label>
                <br />
                <input required maxLength={40} className='join__inpBox' type="text" id="bd__add" />
                <br /><br />
                <label htmlFor="name">No. of Accompany Person: *</label>
                <br />
                <input required className='join__inpBox' type="number" maxLength={1} id="bd__accp" />
                <br /><br />
                <label htmlFor="name"> ID Type: *</label>
                <br />
                <select className='join__inpBox' id="bd__itype">
                    <option value="">Select Type of ID</option>
                    <option value="Aadhar Card">Aadhar Card</option>
                    <option value="Driving License">Driving License</option>
                    <option value="Voter Card">Voter Card</option>
                    <option value="Official ID">Official ID</option>
                </select>
                <br /><br />
                <label htmlFor="name"> ID Number.: *</label>
                <br />
                <input required className='join__inpBox' type="text" id="bd__idnum" />
                <br /><br />
                <button style={{ height: 35, width: 260, backgroundColor: 'orange', color: 'white' }} onClick={handlePayment}>Proceed</button>
            </center>
        </div>
    )
}

export default BookingDetails;