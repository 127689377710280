import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { httpsCallable } from 'firebase/functions';
const firebaseConfig = {
  apiKey: "AIzaSyCxa0IBLMIj6DBumOwICRvpnmmXV6mWCiA",
  authDomain: "cinchonaguesthouse.firebaseapp.com",
  projectId: "cinchonaguesthouse",
  storageBucket: "cinchonaguesthouse.appspot.com",
  messagingSenderId: "148464699202",
  appId: "1:148464699202:web:28098eb6025b817a9aaadb"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export const createRazorpayOrder = httpsCallable('createRazorpayOrder');
if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { app, db, auth, functions };
