import React from 'react';
import './ComingSoon.css'; // Import the CSS file for styling

const ComingSoon = () => {
  return (
    <div className="construction-container">
      <div className="construction-header">
        <img 
          src="/govticon.png" 
          alt="Directorate of Cinchona and Other Medicinal Plants Logo" 
          className="logo" 
        />
        <h1>Directorate of Cinchona and Other Medicinal Plants</h1>
      </div>
      <div className="construction-content">
        <div className="animated-icon">&#128736;</div>
        <h2 className="blinking-text">Site Under Construction</h2>
        <p>We will be launching soon. Thank you for your patience and continued support.</p>
      </div>
    </div>
  );
};

export default ComingSoon;
